import React from "react";
import Button from "../UI/Button";
import { navigate } from "gatsby";
import { BlogPostStyled, Date, Text } from "./BlogPost.styled";
import Markdown from "markdown-to-jsx";

const BlogPost = ({ post }) => (
  <BlogPostStyled>
    {/* Botóon para volver a los posts */}
    <h2>{post.title.es}</h2>
    <Date>Fecha: {post.date}</Date>
    <Text>{<Markdown>{post.description.es}</Markdown>}</Text>
    <Button text="Leer más artículos" onClick={() => navigate("/blog/")} />
    {/* Botón para ver otros posts */}
  </BlogPostStyled>
);

export default BlogPost;
