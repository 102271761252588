import styled from "styled-components";

export const BlogPostStyled = styled.article`
  max-width: 660px;
  margin: auto;
  font-family: Inter, sans-serif;
  color: var(--color__dark);

  h2 {
    font-size: 52px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 24px;
  }
`;

export const Text = styled.div`
  border-bottom: 2px solid var(--lightGrey);
  padding-bottom: 50px;
  margin-top: 36px;
  margin-bottom: 36px;
  p {
    margin: 14px auto 20px;
    line-height: 31.5px;
    font-size: 18px;
  }

  h3 {
    margin: 45px 0 30px;
    font-size: 24px;
    font-weight: 800;
    color: var(--color__primary);
  }

  h4 {
    margin: 45px 0 20px;
    font-size: 20px;
    font-weight: 800;
  }
    
  /* Styles for unordered list */
  /* Changed flex for block so the items may not display properly */
  /* The reason for changing the flex property is that bullet points don't show properly
  when the list is displayed as a flex container. So it's a tradeoff*/
  ul {
    font-size: 18px;
    margin: 15px 0;
    text-align: justify;

    li {
      //display: flex;
      gap: 5.625px;
      margin: 5.625px 0 5.625px 22.5px;
      padding-left: 22.5px;
      line-height: 22.5px;
      list-style-type: disc;

      @media (max-width: 1127px) {
        padding-left: 11.25px
        }
      }
    }
  }
`;

export const Date = styled.p`
  font-size: 1.25rem;
  margin: 16px 0px 10px;
  letter-spacing: -0.4px;
  color: var(--midDarkGrey);
  text-align: center;
`;
