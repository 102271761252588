import React from "react";

import { graphql } from "gatsby";
import BlogPost from "../../components/BlogPost";
import SEO from "../../components/SEO";

const Post = ({ data }) => {
  const { post } = data;

  return (
    <>
      <SEO title={post.title.es} />
      <section className="section">
        <div className="container">
          <BlogPost post={post} />
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    post: sanityBlog(slug: { current: { eq: $slug } }) {
      title {
        es
      }
      description {
        es
      }
      id
      date
      image {
        asset {
          url
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

export default Post;
